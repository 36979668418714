Mosaic.setPlugins([require('./../node_modules/@scandipwa/webpack-i18n-runtime/src/plugin/App.plugin.js'),
require('./../packages/kemana-404-redirect/src/plugin/Kemana404Redirect.plugin.js'),
require('./../packages/kemana-product-view-report/src/plugin/KemanaProductViewReport.plugin.js'),
require('./../packages/kemana-google-tag-manager/src/plugin/kemanaGoogleTagManager.plugin.js'),
require('./../packages/kemana-store-pickup-plugin/src/plugin/KemanaStorePickup.plugin.js'),
require('./../packages/kemana-discount-card/src/plugin/KemanaDIscountCard.plugin.js'),
require('./../packages/kemana-cashback-points/src/plugin/KemanaMyAccountCashbackPoints.plugin.js'),
require('./../packages/kemana-account-store-credit/src/plugin/KemanaAccountStoreCredit.plugin.js'),
require('./../packages/kemana-free-payment-method/src/plugin/KemanaFreePaymentMethod.plugin.js'),
require('./../packages/kemana-date-picker/src/plugin/KemanaDatePicker.plugin.js'),
require('./../packages/kemana-track-shipping/src/plugin/KemanaTrackShipping.plugin.js'),
require('./../packages/kemana-cancel-orders/src/plugin/KemanaCancelOrders.plugin.js'),
require('./../packages/kemana-return-orders/src/plugin/KemanaReturnOrder.plugin.js'),
require('./../packages/kemana-events/src/plugin/KemanaEvents.plugin.js'),
require('./../packages/kemana-payment-methods/src/plugin/KemanaPaymentMethods.plugin.js'),
require('./../packages/kemana-payment-methods/src/plugin/KemanaPaymentMethods.query.plugin.js'),
require('./../packages/kemana-payment-methods/src/plugin/KemanaPaymentMethods.route.plugin.js'),
require('./../packages/kemana-free-gifts/src/plugin/FreeGifts.plugin.js'),
require('./../packages/kemana-payment-shipping-logo/src/plugin/PaymentShippingLogo.plugin.js'),
require('./../packages/kemana-password-field/src/plugin/KemanaPasswordField.plugin.js'),
require('./../packages/kemana-store-locator/src/plugin/KemanaStoreLocator.plugin.js'),
require('./../packages/gift-wrapper/src/plugin/GiftWrapper.plugin.js'),
require('./../packages/blog-module/src/plugin/BlogModule.plugin.js'),
require('./../packages/kemana-gift-card-product-type/src/plugin/GiftCardProductType.plugin.js'),
require('./../packages/kemana-insta-feed/src/plugin/KemanaInstaFeed.plugin.js'),
require('./../packages/kemana-page-builder/src/plugin/CmsPage.container.plugin.js'),
require('./../packages/kemana-page-builder/src/plugin/Html.component.plugin.js'),
require('./../packages/kemana-page-builder/src/plugin/sample.plugin.js')]);
import { render } from 'react-dom';

import App from 'Component/App';

import 'Util/Polyfill';
import 'Util/CustomPolyFill';
import 'Style/main';

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        const swUrl = '/service-worker.js';
        navigator.serviceWorker.register(swUrl, { scope: '/' });
    });
}

render(<App />, document.getElementById('root'));
