import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

const componentDidMount = async () => {
    history.push({ pathname: appendWithStoreCode('/') });
};

export default {

    'Route/NoMatch/Container': {
        'member-function': {
            componentDidMount
        }
    }
};
